import { bookingsApiTemplateSlice as api } from '../state/bookings/bookingsApiTemplateSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listBookings: build.query<ListBookingsApiResponse, ListBookingsApiArg>({
      query: (queryArg) => ({
        url: `/bookings`,
        params: {
          pageSize: queryArg.pageSize,
          pageNumber: queryArg.pageNumber,
          vehicleIds: queryArg.vehicleIds,
          vehicleGroupIds: queryArg.vehicleGroupIds,
          arrivalBefore: queryArg.arrivalBefore,
          arrivalAfter: queryArg.arrivalAfter,
          serviceType: queryArg.serviceType,
          status: queryArg.status,
        },
      }),
    }),
    cancelParkingBooking: build.mutation<
      CancelParkingBookingApiResponse,
      CancelParkingBookingApiArg
    >({
      query: (queryArg) => ({
        url: `/parking/bookings/${queryArg.bookingId}`,
        method: 'PATCH',
        body: queryArg.cancelParkingLotRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ListBookingsApiResponse = /** status 200 OK */ BookingList;
export type ListBookingsApiArg = {
  pageSize: number;
  pageNumber: number;
  vehicleIds?: string[];
  vehicleGroupIds?: string[];
  arrivalBefore?: string;
  arrivalAfter?: string;
  serviceType?: ServiceType[];
  status?: BookingStatus[];
};
export type CancelParkingBookingApiResponse =
  /** status 200 The booking was updated successfully */ Booking;
export type CancelParkingBookingApiArg = {
  bookingId: string;
  cancelParkingLotRequest: CancelParkingLotRequest;
};
export type PageResponse = {
  totalPages: number;
  totalItems: number;
};
export type Vin = string;
export type BookParkingLotRequest = {
  /** ID of the location to book a parking for. */
  locationId: string;
  vehicleInfo: {
    /** Asset ID of the vehicle to book the parking spot for. */
    vehicleId: string;
  };
  /** Omitting the trailerInfo empty indicates that the truck does not have a trailer. */
  trailerInfo?: {
    licensePlate: string;
    vin?: Vin;
  };
  /** Arrival time in UTC. Needs to be before `departureTime`. Needs to be in the future for new Bookings. */
  arrivalTime: string;
  /** Departure time in UTC. Needs to be after `arrivalTime`. Needs to be in the future for new Bookings. */
  departureTime: string;
  /** Full name of the driver who will park the vehicle. IDs will usually be checked upon arrival. */
  driverName: string;
  /** Custom reference that can be provided by user to identify bookings */
  bookingReference?: string;
  /** Language in which the entry instructions should be given. Formatted as per ISO 639-1.
    If omitted, instructions will be returned in English.
     */
  instructionLanguage?: InstructionLanguage;
};
export type MonetaryValue = string;
export type Currency = string;
export type MonetaryAmount = {
  value: MonetaryValue;
  currency: Currency;
};
export type Booking = BookParkingLotRequest & {
  id: string;
  status: BookingStatus;
  /** RIO user ID ('sub' field of access token) of the user who created the booking */
  createdBy: string;
  serviceType: ServiceType;
  /** ID of the payment method that was used for booking */
  paymentMethodId: string;
  estimatedPrice: MonetaryAmount;
  /** Entry instructions as transmitted from the service provider */
  entryInstructions: {
    instructions?: string;
    entryCode?: string;
    reportAt?: string;
    entryLink?: string;
    locationReservationNumber?: string;
    emergencyPhone?: string;
  };
};
export type BookingList = PageResponse & {
  items: Booking[];
};
export type ErrorResponse = {
  message: string;
  errorCode: ErrorCode;
  fields: string[];
};
export type CancelParkingLotRequest = {
  status: Status;
};
export enum InstructionLanguage {
  En = 'en',
  Fr = 'fr',
  Ru = 'ru',
  Da = 'da',
  Es = 'es',
  Cs = 'cs',
  It = 'it',
  Hu = 'hu',
  De = 'de',
  Ro = 'ro',
  Pl = 'pl',
  Nl = 'nl',
  Pt = 'pt',
  Uk = 'uk',
}
export enum BookingStatus {
  Planned = 'PLANNED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}
export enum ServiceType {
  Fueling = 'FUELING',
  Breakdown = 'BREAKDOWN',
  Parking = 'PARKING',
  Washing = 'WASHING',
}
export enum ErrorCode {
  InvalidPathParameters = 'INVALID_PATH_PARAMETERS',
  InvalidQueryParameters = 'INVALID_QUERY_PARAMETERS',
  InvalidPayload = 'INVALID_PAYLOAD',
  InvalidFuelCard = 'INVALID_FUEL_CARD',
  InvalidStatusChange = 'INVALID_STATUS_CHANGE',
  TransactionStateNotCancelable = 'TRANSACTION_STATE_NOT_CANCELABLE',
  PoiNotFound = 'POI_NOT_FOUND',
  OnlinePaymentNotSupported = 'ONLINE_PAYMENT_NOT_SUPPORTED',
  PaymentMethodActive = 'PAYMENT_METHOD_ACTIVE',
  VehicleNotFound = 'VEHICLE_NOT_FOUND',
  VehicleServiceNotActivated = 'VEHICLE_SERVICE_NOT_ACTIVATED',
  VehicleDoesNotHaveLicensePlate = 'VEHICLE_DOES_NOT_HAVE_LICENSE_PLATE',
  PaymentMethodNotFound = 'PAYMENT_METHOD_NOT_FOUND',
  PaymentMethodNotUsableAtLocation = 'PAYMENT_METHOD_NOT_USABLE_AT_LOCATION',
  BookingNotCancelable = 'BOOKING_NOT_CANCELABLE',
  UnknownError = 'UNKNOWN_ERROR',
}
export enum Status {
  Canceled = 'CANCELED',
}
export const { useListBookingsQuery, useCancelParkingBookingMutation } =
  injectedRtkApi;
